<template>
  <div class="container">
    <br /><br /><br /><br />
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Profile",
};
</script>
